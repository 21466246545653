body {
  overflow-x: hidden;
  overflow-y: hidden;
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5 {
  font-family: Roboto;
  color: #565656;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url("/assets/roboto-cyrillic.woff2") format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url("/assets/roboto-latin.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url("/assets/roboto-cyrillic-ext.woff2") format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url("/assets/roboto-latin-ext.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/************ start: fix firefox styles here *****************/
.dropdown button.nav-icon.dropdown-toggle {
  display: block;
}

/************ end:   fix firefox styles here *****************/

.itech-dashboard-container {
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-image: url('/assets/images/logo.png');
  position: absolute;
  left: 0;
  top: 36px;
  right: 10px;
  bottom: 10px;
  display: block;
}

.itech-dashboard-container > div:last-child {
  position: absolute;
  right: 0;
  top: 0;
}

.itech-dashboard-container .card {
  width: 400px;
  margin: 20px;
}

.it-deletedreason-text {
  color: maroon;
}

.itech-doc-container {
  display: flex;
  flex-direction: row;
}

.itech-doc-content {
  flex: auto;
}

.itech-doc-image {
  width: 100px;
  text-align: center;
  padding: 2px 10px 2px 10px;
}

.itech-doc-image > img, .itech-doc-image > clr-icon {
  width: 80px;
  height: 80px;
}

.itech-doc-list-image {
  width: 16px;
  margin-right: 2px;
  display: inline-block;
}

.itech-doc-list-image > img, .itech-doc-list-image > clr-icon {
  width: 16px;
  height: 16px;
}

.form {
  padding: 0 0 0 5px;
  user-select: text;
}

.form .form-group {
  padding: 0 0 0 6rem;
}

.form-block.itech-block-inline {
  display: inline-block;
  vertical-align: top;
}

.form .form-group select {
  width: 170px;
}

.form .itech-group-divider {
  display: block;
  margin: 5px 0 5px 0;
  height: 1px;
  margin-left: 5%;
  margin-right: 5%;
  border-bottom: 1px solid silver;
}

.form .form-group .itech-text-field {
  margin-top: 0.25rem;
  font-weight: bold;
  cursor: text;
  max-width: 200px;
  overflow-x: auto;
  word-break: break-all;
}

.form .form-group .itech-text-field-no-wrap {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form .form-group .itech-text-field::after {
  content: ' ';
  white-space: pre;
}

.itech-text-wall.itech-form-field {
  margin-top: 0.25rem;
  cursor: text;
}

.form .form-group select.itech-control-default {
  width: 100%;
}

.form .form-group input[type=date] {
  width: 150px;
}

.form .form-block.itech-block-medium .form-group {
  padding: 0 0 0 8rem;
}

.form .form-block.itech-block-large .form-group {
  padding: 0 0 0 12rem;
}

.form .form-block.itech-block-xlarge .form-group {
  padding: 0 0 0 18rem;
}

.form .form-block.itech-block-large .form-group > label:first-child {
  max-width: 11.8rem;
  width: 11.8rem;
}

.form .form-block.itech-block-xlarge .form-group {
  padding: 0 0 0 18rem;
}

.form .form-block.itech-block-xlarge .form-group > label:first-child {
  max-width: 17.8rem;
  width: 17.8rem;
}

.form .itech-block-tiny .form-group {
  padding: 0 0 0 1rem;
}

.form .itech-block-little .form-group {
  padding: 0 0 0 2rem;
}

@media (min-width: 1200px) {
  .form .form-block.itech-block-inline + .form-block.itech-block-inline {
    margin: 0 0 0 10px;
    padding: 0 0px 0 20px;
    border-left: 1px solid silver;
  }
}

.itech-form-block-v-divider:before {
  content: '';
}

.form .form-block.itech-block-normal .form-group > label:first-child {
  width: 6rem;
}

.form .form-group select.itech-control-m-large, .form .form-group .itech-control-m-large select,
.form .form-group textarea.itech-control-m-large, .form .form-group .itech-control-m-large textarea,
.form .form-group input.itech-control-m-large, .form .form-group .itech-control-m-large input,
.form .form-group .itech-text-field.itech-control-m-large, .form .form-group .itech-control-m-large .itech-text-field,
.form .form-group .itech-control-m-large, .form .form-group .itech-control-m-large .itech-selected-lookup-text,
.form .form-group .itech-control-m-large .itech-lookup-popup-container {
  width: 312px;
  max-width: 312px;
}

.form .form-group select.itech-control-large, .form .form-group .itech-control-large select,
.form .form-group textarea.itech-control-large, .form .form-group .itech-control-large textarea,
.form .form-group input.itech-control-large, .form .form-group .itech-control-large input,
.form-group-left-absolute .itech-control-large input,
.form .form-group .itech-text-field.itech-control-large, .form .form-group .itech-control-large .itech-text-field,
.form .form-group .itech-control-large, .form .form-group .itech-control-large .itech-selected-lookup-text,
.form .form-group .itech-control-large .itech-lookup-popup-container {
  width: 332px;
  max-width: 332px;
}

.form .form-group select.itech-control-larger, .form .form-group .itech-control-larger select,
.form .form-group textarea.itech-control-larger, .form .form-group .itech-control-larger textarea,
.form .form-group input.itech-control-larger, .form .form-group .itech-control-larger input,
.form-group-left-absolute .itech-control-larger input,
.form .form-group .itech-text-field.itech-control-larger, .form .form-group .itech-control-larger .itech-text-field,
.form .form-group .itech-control-larger, .form .form-group .itech-control-larger .itech-selected-lookup-text,
.form .form-group .itech-control-larger .itech-lookup-popup-container {
  width: 400px;
  max-width: 400px;
}

.form .form-group select.itech-control-xlarge, .form .form-group .itech-control-xlarge select,
.form .form-group textarea.itech-control-xlarge, .form .form-group .itech-control-xlarge textarea,
.form .form-group input.itech-control-xlarge, .form .form-group .itech-control-xlarge input,
.form-group-left-absolute .itech-control-xlarge input,
.form .form-group .itech-text-field.itech-control-xlarge, .form .form-group .itech-control-xlarge .itech-text-field,
.form .form-group .itech-control-xlarge, .form .form-group .itech-control-xlarge .itech-selected-lookup-text,
.form .form-group .itech-control-xlarge .itech-lookup-popup-container {
  width: 500px;
  max-width: 500px;
}

.form .form-group select.itech-control-xxlarge, .form .form-group .itech-control-xxlarge select,
.form .form-group textarea.itech-control-xxlarge, .form .form-group .itech-control-xxlarge textarea,
.form .form-group input.itech-control-xxlarge, .form .form-group .itech-control-xxlarge input,
.form-group-left-absolute .itech-control-xxlarge input,
.form .form-group .itech-text-field.itech-control-xxlarge, .form .form-group .itech-control-xxlarge .itech-text-field,
.form .form-group .itech-control-xxlarge, .form .form-group .itech-control-xxlarge .itech-selected-lookup-text,
.form .form-group .itech-control-xxlarge .itech-lookup-popup-container {
  width: 800px;
  max-width: 800px;
}

.form > .itech-block-normal > .form-group select.itech-control-medium,
.form > .itech-block-normal > .form-group .itech-control-medium select,
.form > .itech-block-normal > .form-group textarea.itech-control-medium,
.form > .itech-block-normal > .form-group .itech-control-medium textarea,
.form > .itech-block-normal > .form-group input.itech-control-medium,
.form > .itech-block-normal > .form-group .itech-control-medium input,
.form > .itech-block-normal > .form-group .itech-control-medium {
  width: 188px;
  max-width: 188px;
}

.form .form-group select.itech-control-medium, .form .form-group .itech-control-medium select,
.form .form-group textarea.itech-control-medium, .form .form-group .itech-control-medium textarea,
.form .form-group input.itech-control-medium, .form .form-group .itech-control-medium input,
.form .form-group .itech-text-field.itech-control-medium, .form .form-group .itech-control-medium .itech-text-field,
.form .form-group .itech-control-medium, .form .form-group .itech-control-medium .itech-selected-lookup-text {
  width: 250px;
  max-width: 250px;
}

.form .form-group select.itech-control-normal, .form .form-group .itech-control-normal select,
.form .form-group textarea.itech-control-normal, .form .form-group .itech-control-normal textarea,
.form .form-group input.itech-control-normal, .form .form-group .itech-control-normal input,
.form .form-group .itech-text-field.itech-control-normal, .form .form-group .itech-control-normal .itech-text-field,
.form .form-group .itech-control-normal, .form .form-group .itech-control-normal .itech-selected-lookup-text {
  width: 150px;
  max-width: 150px;
}

.form .form-group .itech-plain-text-control {
  height: 24px;
  margin: 6px 0 6px 0;
}

.form-group.itech-double-height > label.tooltip-validation {
  height: 2rem;
  padding-top: 0;
}

.form-group.itech-double-height > label.tooltip-validation > textarea {
  padding-top: 0;
  margin-top: 0;
  height: 2rem;
  max-height: 2rem;
  min-height: 2rem;
  overflow-y: hidden;
}

.form-group.itech-height-x4 > label.tooltip-validation {
  height: 4rem;
  padding-top: 0;
}

.form-group.itech-height-x4 > label.tooltip-validation > textarea {
  padding-top: 0;
  margin-top: 0;
  height: 4rem;
  max-height: 4rem;
  min-height: 4rem;
}

.form-group.v-resize > label.tooltip-validation > textarea {
  max-height: none;
  height: auto;
}

.form-group.v-resize > label.tooltip-validation {
  height: auto;
}

.form .form-group select.itech-control-small, .form .form-group .itech-control-small select,
.form .form-group textarea.itech-control-small, .form .form-group .itech-control-small textarea,
.form .form-group input.itech-control-small, .form .form-group .itech-control-small input {
  width: 88px;
}

.form .form-group select.itech-control-s-tiny, .form .form-group .itech-control-s-tiny select,
.form .form-group textarea.itech-control-s-tiny, .form .form-group .itech-control-s-tiny textarea,
.form .form-group input.itech-control-s-tiny, .form .form-group .itech-control-s-tiny input {
  width: 50px;
}

.form .form-group select.itech-control-tiny, .form .form-group .itech-control-tiny select,
.form .form-group textarea.itech-control-tiny, .form .form-group .itech-control-tiny textarea,
.form .form-group input.itech-control-tiny, .form .form-group .itech-control-tiny input {
  width: 70px;
}

.tooltip.tooltip-validation > input.itech-control-tiny,
.itech-control-tiny .tooltip.tooltip-validation > input {
  padding-right: 1px;
  padding-left: 1px;
}

.form .form-block.itech-block-medium {
  margin: 0;
}

.form .form-block.itech-block-normal {
  margin: 0;
}

.form .form-group.itech-group-whole-line > label:first-child {
  display: none;
}

.form-group > label:first-child > .checkbox-inline input[type=checkbox] + label:before,
.form-group > label:first-child > .checkbox-inline input[type=checkbox] + label:after {
  top: 0;
  margin-left: 3px;
}

.form .form-group > label:first-child {
  line-height: 0.6rem;
  padding-top: 0.2rem;
  max-width: 180px;
}

.form .form-group > label:first-child > .radio-inline {
  line-height: 0.6rem;
  margin: 0;
  max-width: 180px;
}

.form .form-group > label:first-child > .radio-inline > label {
  line-height: 0.6rem;
}

.form .form-group.itech-group-whole-line {
  padding: 0 !important;
}

.form label.tooltip > div.select {
  margin: 0;
}

.form label.tooltip > div.select:after {
  right: 1rem;
}

.form label.tooltip > div.select:before {
  content: '';
  display: block;
  height: 1px;
}

.form label.tooltip > div.select > select {
  padding-right: 1.8rem;
}

.form label.tooltip > div.input > input, .form label.tooltip > div.link {
  padding-right: 1rem;
}

.form label.tooltip > div.link button.itech-inline-tool-btn {
  margin-top: 0px;
}

.form label.tooltip > div.link a.itech-person-link {
  padding-left: 6px;
}

.itech-form-actions {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid silver;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.modal-footer.itech-form-actions {
  border-top: none;
}

.no-border.itech-form-actions {
  border-top: none;
}

.tooltip.tooltip-validation.invalid > .select {
  border-bottom: 1px solid #c92100;
  background: linear-gradient(180deg, transparent 95%, #c92100 0);
  transition: none;
}

.tooltip.tooltip-validation.invalid > textarea:focus + .tooltip-content,
.tooltip.tooltip-validation.invalid > .itech-focused + .tooltip-content {
  background: #c92100;
  visibility: visible;
  opacity: 1;
}

.tooltip.tooltip-validation.invalid.tooltip-top-left > textarea:focus + .tooltip-content,
.tooltip.tooltip-validation.invalid.tooltip-bottom-left > textarea:focus + .tooltip-content {
  right: 0;
  left: auto;
  margin-right: 0.58333rem;
}

.tooltip.tooltip-validation.invalid.tooltip-right > .itech-focused + .tooltip-content {
  left: 100%;
  right: auto;
  margin: 0 0 0 0.58333rem;
}

.tooltip.tooltip-validation.invalid.tooltip-right > .itech-focused + .tooltip-content::before {
  border-top-color: #c92100;
  border-right-color: #c92100;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.tooltip.tooltip-validation.invalid > textarea:focus + .tooltip-content,
.tooltip.tooltip-validation.invalid.tooltip-top-right > textarea:focus + .tooltip-content,
.tooltip.tooltip-validation.invalid.tooltip-bottom-right > textarea:focus + .tooltip-content {
  left: 100%;
  right: auto;
  margin-left: -0.58333rem;
}

.tooltip.tooltip-validation.invalid > .select > select {
  border: none;
}

.tooltip.tooltip-validation.invalid > .tooltip-content {
  z-index: 20;
}

.nav[role=tablist] button:focus {
  outline: none;
}

button[clrTabLink] > img, button[clrTabLink] > clr-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.itech-content-header {
  padding: 20px 10px 20px 10px;
  margin: -10px -10px 10px -10px;
  color: #5079a8;
  border-bottom: 1px solid silver;
  background: white;
  font-size: 36px;
  cursor: default;
  user-select: none;
  text-shadow: 1px 1px 3px #888;
}

.itech-common-wrap .itech-content-header {
  margin: 10px 10px 20px 0;
  padding: 0;
  border-bottom: none;
  background: inherit;
}

.itech-no-margin-bottom {
  margin-bottom: 0;
}

.itech-content-header > clr-icon {
  margin: 0 0 8px 0;
}

.btn.btn-link.itech-inline-link-btn {
  padding: 0;
  margin: 0;
}

.btn.btn-link.itech-inline-tool-btn {
  padding: 0;
  margin: 5px 0 0 0;
  width: 20px;
  height: 20px;
  min-width: 20px;
  line-height: 0;
}

.btn.btn-link.itech-inline-tool-btn:focus {
  outline: none;
}

textarea:not([readonly]) {
  background: linear-gradient(to top, #0094d2 1.5px, transparent 1.5px) no-repeat;
  background-size: 0% 100%;
  transition: background-size 0.2s ease;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid silver;
  padding: 0 28px 0 6px;
  outline: none;
}

textarea:not([readonly]):focus {
  border-bottom: 1px solid #0094d2;
  background-size: 100% 100%;
}

.tooltip.tooltip-validation.invalid > textarea {
  border-bottom: 1px solid #c92100;
  background: linear-gradient(to bottom, transparent 95%, #c92100 95%);
  transition: none;
}

.form .form-block .form-group .tooltip.tooltip-validation textarea:focus {
  box-shadow: none;
}

.itech-documents .stack-block.itech-doc-block {
  flex-flow: column wrap;
  align-items: stretch;
}

.itech-documents .stack-block.itech-doc-block > .stack-block-label {
  width: 100%;
  max-width: 100%;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.itech-documents .stack-block.itech-doc-block.itech-open > .stack-block-label + .stack-block-content {
  display: flex;
}

.itech-documents .stack-block.itech-doc-block.itech-open > .stack-block-label {
  font-weight: bold;
  font-size: larger;
  background-color: #e9f4fa;
}

.itech-documents .stack-view .stack-block-label {
  flex: 2 2 auto;
  max-width: 95%;
}

.itech-documents .stack-view .stack-block-content {
  width: 1%;
}

.itech-documents .stack-block.itech-doc-block:not(.itech-open) > .stack-block-label:hover {
  background-color: #eee;
}

.itech-documents .stack-block.itech-doc-block > .stack-block-content {
  width: 100%;
  max-width: 100%;
  display: none;
}

.itech-documents .stack-view {
  overflow-x: hidden;
}

.itech-documents .stack-block.itech-doc-block.itech-open > .stack-block-label:before {
  transform: rotate(0deg);
}

.itech-documents .stack-block.itech-doc-block > .stack-block-label:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2012%2012%22%3E%0A%20%20%20%20%3Cdefs%3E%0A%20%20%20%20%20%20%20%20%3Cstyle%3E.cls-1%7Bfill%3A%239a9a9a%3B%7D%3C%2Fstyle%3E%0A%20%20%20%20%3C%2Fdefs%3E%0A%20%20%20%20%3Ctitle%3ECaret%3C%2Ftitle%3E%0A%20%20%20%20%3Cpath%20class%3D%22cls-1%22%20d%3D%22M6%2C9L1.2%2C4.2a0.68%2C0.68%2C0%2C0%2C1%2C1-1L6%2C7.08%2C9.84%2C3.24a0.68%2C0.68%2C0%2C1%2C1%2C1%2C1Z%22%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
  transform: rotate(-90deg);
}

.itech-documents clr-stack-label > div {
  width: 100%;
  height: 100%;
  margin: -5px 0 -5px -20px;
  padding: 5px 0 5px 20px;
  display: inline-flex;
}

.itech-documents clr-stack-label > div > div:first-child {
  flex: 10 10 auto;
  margin: -5px 0 -5px 0;
  padding: 5px 0 5px 0;
}

.itech-documents .btn-link.itech-inline-tool-btn {
  margin: 4px 0 0 10px;
  width: auto;
}

.person-request-list-item span.label {
  padding: 0 5px 0 5px;
  margin: 0;
  height: 18px;
}

.itech-list-item-changed::after {
  content: '***';
  font-weight: bold;
  color: red;
}

.itech-person-link {
  cursor: pointer;
  color: #0094d2;
  font-weight: normal;
}

.itech-person-link:not(.not-icon):before {
  content: '\21F2';
}

.itech-soc-services-tree .clr-tree-node-content-container {
  align-items: flex-start;
}

.itech-soc-services-tree {
  font-size: 12px;
}

.datagrid-cell {
  word-break: break-all;
}

.edit-page-tab {
  margin: 10px 10px 5px 5px;
}

.edit-page-tab.itech-quarantine-page-tab {
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  top: 34px;
  right: 0;
  left: 0;
  bottom: 0;
}

.itech-text-wall {
  font-size: 11px;
  line-height: 12px;
}

.itech-no-data-text {
  color: #A6A6A6;
  font-size: 16px;
  font-weight: bold;
}

.itech-no-data-text-light {
  color: #A6A6A6;
}

textarea[readonly] {
  border: none;
}

.in-table-link {
  color: #0094d2;
  word-break: normal;
}

.itech-link {
  cursor: pointer;
}

.itech-link:focus {
  text-decoration: none;
}

.itech-link.edit-layout {
  color: darkmagenta;
}

.itech-control-desc {
  color: green;
  font-size: 10px;
  line-height: 12px;
  display: block;
}

.itech-desc-error {
  color: red;
}

.itech-control-desc.itech-desc-inline {
  display: inline-block;
}

.itech-service-adding-badge {
  color: green;
}

.tooltip-content {
  opacity: 0.9 !important;;
  pointer-events: none !important;
}

.itech-panel {
  border: 1px solid silver;
  padding: 10px;
}

.itech-panel > .itech-panel-heading {
  padding: 0 5px 0 5px;
  margin: -10px -10px 10px -10px;
  background-color: #a8a8a8;
  color: white;
  font-weight: bold;
  font-size: 12px
}

.itech-incomings-panel {
  min-width: 690px;
  margin: 10px 0 0 0 !important;
}

.itech-panel.itech-incomings-panel {
  padding-bottom: 0;
  margin-left: 30px !important;
}

.form .itech-incomings-panel .form-group {
  margin: 0;
}

.form .itech-incomings-panel .form-group .tooltip-validation.tooltip {
  margin: 0 5px 0 0;
}

.form .itech-incomings-panel > div.itech-incoming-hi {
  padding-left: 182px;
}

.form-block.itech-independence-view .stack-view .stack-block-label {
  max-width: 45%;
  width: 45%;
}

.form-block.itech-independence-view {
  min-width: 770px;
  user-select: none;
}

.form-block.itech-independence-view .stack-view .checkbox-inline {
  width: 90px;
}

.itech-independence-view-header {
  display: flex;
  flex: 2 2 auto;
  align-items: center;
  user-select: none;
}

.itech-independence-view-header > div:first-child {
  max-width: 45%;
  width: 45%;
}

.itech-independence-view-header > div:not(:first-child) {
  width: 90px;
  text-align: center;
}

.form .form-group .dropdown-menu .dropdown-item {
  margin: 0;
}

.itech-no-margin-bottom ~ h4 {
  margin-top: 0;
}

.itech-common-wrap {
  position: absolute;
  left: 0;
  top: 80px;
  right: 0;
  bottom: 0;
  margin: 10px;
}

.itech-common-wrap.no-pager {
  top: 32px;
}

.itech-common-wrap > div:first-child > h1:first-child,
.itech-common-wrap > div:first-child > .itech-content-header:first-child {
  margin-top: 0;
}

.itech-common-wrap > div:first-child {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 64px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.itech-common-wrap.it-xscroll > div:first-child {
  overflow-x: scroll;
}

.itech-common-wrap > div:last-child {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  height: 64px;
}

h4 {
  color: #565656;
}

.form-block .form-group label:first-child > .checkbox-inline {
  margin: 0;
}

.form-block .form-group label:first-child > .checkbox-inline label {
  font-size: 12px;
  line-height: 13px;
}

app-checkbox-select > div:first-child {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  font-size: 12px;
}

.app-checkbox-select-border {
  border: 1px solid silver;
}

app-checkbox-select > div > div.checkbox.itech-no-margins {
  padding-right: 20px;
  margin: 0 !important;
}

app-checkbox-select > div > div > div > div.checkbox.itech-no-margins {
  padding-right: 20px;
  margin: 0 !important;
}

app-choose-tree-nodes .clr-treenode-link {
  line-height: 16px;
}

.query-manager-navigation .clr-treenode-link, .query-manager-navigation .clr-treenode-caret {
  line-height: 16px;
  height: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.query-manager-navigation .clr-treenode-link.itech-query {
  white-space: normal;
  height: auto;
  font-size: 10px;
  line-height: 12px;
}

.query-manager-navigation .clr-tree-node-content-container {
  overflow-x: hidden;
}

.query-manager-navigation .clr-treenode-caret:focus {
  outline: none;
}

.sidenav .sidenav-content .nav-group .nav-list, .sidenav .sidenav-content .nav-group label {
  padding: 0 0 0 10px;
}

.sidenav .sidenav-content > .nav-link {
  margin: 1rem 0 0 0;
  padding-left: 10px;
}

.sidenav .nav-group .nav-list .nav-link.itech-child1 {
  padding-left: 30px;
  font-size: 12px;
  line-height: 12px;
}

.sidenav .nav-group .nav-list .nav-link.itech-child1:before {
  content: '\21B3';
  color: silver;
  position: relative;
  left: -10px;
}

.sidenav .nav-group .nav-list .nav-link.itech-child2 {
  padding-left: 50px;
  font-size: 12px;
  line-height: 12px;
}

.sidenav .nav-group .nav-list .nav-link.itech-child2:before {
  content: '\21B3\21B3';
  color: silver;
  position: relative;
  left: -10px;
}

.sidenav {
  max-width: 12rem;
  min-width: 8rem;
  width: 15%;
}

#not-logged-in {
  background: url('/assets/images/logo.png') no-repeat right bottom;
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 100%;
}

#not-logged-in div {
  margin: 80px auto 0;
  width: 800px;
}

#not-logged-in button {
  margin-top: 30px;
}

.itech-v-resize > .itech-form-group-content {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.itech-v-header-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.itech-v-header-content > *:first-child {
  flex: 1 1 auto;
}

.itech-v-header-content > *:last-child {
  flex: 1000 1 auto;
}

.itech-smev-msg-container {
  display: flex;
  flex-direction: row;
}

.itech-smev-msg-content {
  flex: auto;
  font-size: 12px;
}

.itech-smev-msg-content h3 {
  font-weight: bold;
  font-size: 12px;
  margin: 0;
}

.itech-smev-msg-image {
  width: 52px;
  padding: 2px 10px 2px 10px;
}

.itech-smev-msg-image > img, .itech-smev-msg-image > clr-icon {
  width: 32px;
  height: 32px;
}

.itech-smev-msg-content h3 > span.label {
  padding: 3px 5px 0 5px;
  margin: 0;
  height: 19px;
}

.itech-smev-msg-content a {
  color: #007cbb;
  font-weight: normal;
}

app-smev-message ~ app-smev-message:before {
  content: ' ';
  display: block;
  width: 80%;
  position: relative;
  left: 10%;
  height: 5px;
  border-top: 1px solid silver;
  margin-top: 5px;
}

.itech-smev-msg-text {
  white-space: pre-wrap;
  max-height: 300px;
  line-height: 16px;
  font-size: 13px;
  padding: 5px;
}

.dropdown-menu .btn, .dropdown-menu .dropdown-item {
  height: 1.25rem;
  line-height: 1.25rem;
}

clr-dropdown {
  margin-right: .5rem;
}

.itech-tree-list clr-checkbox:first-child {
  margin-left: 0px !important;
}

.itech-dropdown-overflow {
  height: 400px;
  display: block;
  overflow-y: scroll;
}

.form.itech-highlighted-sections {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.form.itech-highlighted-sections > section.form-block {
  border-bottom: 1px solid silver;
  margin: 0 5px 10px -10px;
  padding: 0 5px 10px 15px;
}

.form.itech-highlighted-sections > section.form-block:last-child {
  border-bottom: none;
}

.form.itech-highlighted-sections > section.form-block > h3 {
  font-size: .741667rem;
  color: rgb(70, 70, 70);
  font-weight: bold;
  margin: 0 0 5px -10px;
  padding: 0;
}

.form.itech-highlighted-sections > app-edit-quarantine-common-territory {
  border-bottom: 1px solid silver;
  margin: 0 5px 10px -10px;
  padding: 0 5px 10px 15px;
}

.form.itech-highlighted-sections > app-edit-quarantine-common-territory:last-child {
  border-bottom: none;
}

.form.itech-highlighted-sections > app-edit-quarantine-common-territory > section.form-block > h3 {
  font-size: .741667rem;
  color: rgb(70, 70, 70);
  font-weight: bold;
  margin: 0 0 5px -10px;
  padding: 0;
}

.full-width-panel {
  padding: 5px;
}

.full-width-details {
  word-wrap: normal;
  word-break: break-all;
  white-space: normal;
  overflow-y: auto;
  max-height: 140px;
  height: 140px;
}

.itech-search-table-with-filter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.itech-search-table-with-filter > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 220px;
}

.itech-search-table-with-filter > div:last-child {
  padding: 5px;
  position: absolute;
  top: 220px;
  left: 0;
  right: 0;
  bottom: 0;
}

.itech-selected-lookup-text {
  position: absolute;
  top: 0;
  left: 8px;
  right: 0;
  z-index: 9;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 32px;
}

.itech-lookup-popup-container {
  position: absolute;
  height: 300px;
  width: 400px;
  top: 24px;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 10;
  background: white;
  border-radius: 0 0 5px 5px;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  overflow-y: scroll;
}

.itech-lookup-popup-container > .itech-lookup-popup-element {
  padding-left: 3px;
  line-height: 1.3em;
  padding-top: 4px;
}

.itech-lookup-popup-container > .itech-lookup-popup-element > .lookup-comments {
  font-size: 0.8em;
  line-height: 0.8em;
  color: rgb(66, 76, 130);
}

.itech-lookup-popup-container > .itech-lookup-popup-clear {
  padding-left: 3px;
  color: silver;
}

.itech-lookup-popup-container > .itech-lookup-popup-clear:hover {
  font-weight: bold;
}

.itech-lookup-popup-container > .itech-lookup-popup-element:hover,
.itech-lookup-popup-container > .itech-lookup-popup-element.itech-selected {
  background-color: #90c4ff;
}

app-text-lookup input[type=text]:hover {
  background-color: #eee !important;
}

.itech-sync-status-lbl > a {
  font-size: 10px;
  text-align: center;
  line-height: 9px
}

.itech-trapped-quarantine-lbl {
  margin-top: 10px;
}

.itech-trapped-quarantine-lbl > a {
  font-size: 12px;
  text-align: center;
  line-height: 12px
}

.itech-signpost-content > .signpost-wrap > .signpost-content-body {
  padding: 1rem 0 0 0;
}

.spinner.spinner-tiny {
  min-height: 0.6rem;
  min-width: 0.5rem;
  height: 0.6rem;
  width: 0.5rem;
  background-position: center;
  background-repeat: no-repeat;
}

.form .form-group span.spinner.spinner-tiny {
  margin: 2px;
}

.clr-radio-wrapper.itech-highlight-checked > input[type="radio"]:checked + label {
  font-weight: bold;
}

.clr-radio-wrapper.itech-highlight-checked > input[type="radio"]:not(:checked) + label {
  color: silver;
}

.itech-dashboard-container > app-mo-map > svg {
  width: 700px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

app-mo-map > svg path {
  stroke: #000000;
  stroke-width: 1px;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-opacity: 1;
  display: inline
}

app-mo-map > svg path.itech-hovered {
  stroke: #0000ff;
  stroke-width: 2px;
}

.itech-dashboard-container > #mo-reports {
  position: absolute;
  left: 0;
  top: 0;
}

app-mo-map > #focused-map-details {
  width: 300px;
  height: 300px;
  position: absolute;
  left: 500px;
  bottom: 20px;
  border: 1px solid silver;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
}

app-mo-map > #focused-map-details > h5 {
  padding: 0 0 5px 0;
  margin: 0;
  border-bottom: 1px solid silver;
  text-align: center;
  width: 100%;
  font-weight: bold;
}

app-confirm-modal .modal-dialog, app-confirm-modal .modal {
  z-index: 2000;
}

app-job-runner-modal .modal-dialog, app-job-runner-modal .modal {
  z-index: 1990;
}

.itech-form-line {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.itech-page-header-extras {
  font-size: small;
  color: navy;
}

.itech-form-preselect-link {
  cursor: pointer;
  font-weight: normal;
  color: silver;
  font-style: italic;
}

.ag-theme-balham .ag-paging-panel {
  color: rgb(0, 0, 0);
  white-space: nowrap;
}

.ag-theme-balham .ag-paging-panel .ag-paging-page-summary-panel {
  display: flex !important;
  display: -webkit-box !important;
}

.ag-cell.invalid, .ag-theme-balham .ag-cell-focus.invalid {
  border: 1px solid #c92100 !important;
}

.form .form-group > label.highlight-warn:first-child {
  color: red;
  font-weight: bold;
}

.itech-search-table-with-filter ag-grid-angular {
  width: 100%;
  height: 100%;
}

.signpost-content-body > h3:first-child {
  margin-top: 10px;
}

table.itech-qua-events-tbl {
  border-collapse: collapse;
  width: 100%;
}

table.itech-qua-events-tbl td, table.itech-qua-events-tbl th {
  border: 1px solid #ddd;
  padding: 4px;
}

table.itech-qua-events-tbl tr:nth-child(even) {
  background-color: #f2f2f2;
}

table.itech-qua-events-tbl tr:hover {
  background-color: #ddd;
}

table.itech-qua-events-tbl > thead td {
  background-color: #50A0A0;
  color: white;
  text-align: center;
  font-weight: bold;
  min-width: 40px;
}

table.itech-qua-events-tbl > tbody td {
  vertical-align: top;
}

table.itech-qua-events-tbl > tbody td:nth-child(1) {
  width: 6%;
  max-width: 6%;
  text-align: center;
}

table.itech-qua-events-tbl > tbody td:nth-child(2) {
  width: 54%;
  max-width: 54%;
}

table.itech-qua-events-tbl > tbody td:nth-child(3) {
  width: 20%;
  max-width: 20%;
}

table.itech-qua-events-tbl > tbody td:nth-child(4) {
  width: 20%;
  max-width: 20%;
}

table.itech-qua-events-tbl > tbody app-combo-lookup.itech-control-m-large select {
  width: 312px;
  max-width: 312px;
}

table.itech-qua-events-tbl > tbody app-combo-lookup.itech-control-large select,
table.itech-qua-events-tbl > tbody input.itech-control-large {
  width: 332px;
  max-width: 332px;
}

table.itech-qua-events-tbl > tbody app-combo-lookup.itech-control-larger select {
  width: 400px;
  max-width: 400px;
}

table.itech-qua-events-tbl > tbody app-combo-lookup.itech-control-xlarge select {
  width: 500px;
  max-width: 500px;
}

table.itech-qua-events-tbl > tbody app-combo-lookup.itech-control-xxlarge select {
  width: 800px;
  max-width: 800px;
}

table.itech-qua-events-tbl > tbody app-combo-lookup.itech-control-xxlarge select {
  width: 800px;
  max-width: 800px;
}

app-query-date-time-filter > label > input[type="text"] {
  width: 110px
}

app-ag-grid-lookup-renderer > div {
  min-height: 24px;
  cursor: pointer;
}

app-ag-grid-lookup-renderer > div.first-grouping {
  padding-left: 10px;
  background-color: #F2F4F5;
}

app-ag-grid-lookup-renderer > div.second-grouping {
  padding-left: 20px;
  background-color: #E4EEF0;
}

app-ag-grid-lookup-renderer > div.third-grouping {
  padding-left: 30px;
  background-color: #F2F4F5;
}

app-ag-grid-lookup-renderer > div.fourth-grouping {
  padding-left: 40px;
  background-color: #E4EEF0;
}

app-ag-grid-lookup-renderer > div.fifth-grouping {
  padding-left: 50px;
  background-color: #F2F4F5;
}

.app-grid-context-menu-btn > clr-icon {
  margin-left: -16px;
}

.checkbox.app-grid-context-menu {
  padding-left: 10px;
}

app-ag-grid-button-renderer > .btn.btn-link {
  padding: 0;
  margin: 0;
  min-width: 100%;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

app-ag-grid-button-renderer > button > clr-icon {
  vertical-align: baseline;
}

.app-grid-editable-button-cell.ag-cell {
  padding: 0;
  text-align: center;
}

.app-grid-editable .ag-cell-error-validation:before {
  position: absolute;
  content: '';
  height: .666667rem;
  width: .666667rem;
  top: .125rem;
  right: .25rem;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20version%3D%221.1%22%20viewBox%3D%225%205%2026%2026%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Cdefs%3E%3Cstyle%3E.clr-i-outline%7Bfill%3A%23a32100%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Ctitle%3Eexclamation-circle-line%3C%2Ftitle%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20class%3D%22clr-i-outline%20clr-i-outline-path-1%22%20d%3D%22M18%2C6A12%2C12%2C0%2C1%2C0%2C30%2C18%2C12%2C12%2C0%2C0%2C0%2C18%2C6Zm0%2C22A10%2C10%2C0%2C1%2C1%2C28%2C18%2C10%2C10%2C0%2C0%2C1%2C18%2C28Z%22%3E%3C%2Fpath%3E%3Cpath%20class%3D%22clr-i-outline%20clr-i-outline-path-2%22%20d%3D%22M18%2C20.07a1.3%2C1.3%2C0%2C0%2C1-1.3-1.3v-6a1.3%2C1.3%2C0%2C1%2C1%2C2.6%2C0v6A1.3%2C1.3%2C0%2C0%2C1%2C18%2C20.07Z%22%3E%3C%2Fpath%3E%3Ccircle%20class%3D%22clr-i-outline%20clr-i-outline-path-3%22%20cx%3D%2217.95%22%20cy%3D%2223.02%22%20r%3D%221.5%22%3E%3C%2Fcircle%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-size: contain;
}

.app-ag-grid-text-lookup-editor > .tooltip {
  width: 100%;
  height: 100%;
}

.app-grid-editable.ag-theme-balham .ag-cell.ag-cell-inline-editing {
  height: 100%;
}

label.app-ag-grid-text-input-editor {
  width: 100%;
}

label.app-ag-grid-text-input-editor > input.app-ag-grid-text-input {
  width: 100%;
  padding: 0 5px;
  border: none;
}

label.app-ag-grid-text-input-editor > input.app-ag-grid-text-input:focus {
  border: none;
  background: none;
}

.ag-theme-balham .ag-fill-handle {
  cursor: crosshair;
}

.itech-many-animal > a > span {
  margin: 0;
  display: inline;
}

.itech-many-animal > a > span > clr-icon {
  height: 10px;
  width: 10px;
}

section.itech-area h1:first-child {
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
  padding: 5px 0 5px 0;
  background-color: rgb(240, 240, 240);
  margin: 5px 0 5px 0px;
  line-height: 15px;
}

app-mercury-sync-status-signpost.itech-signpost-grid-detail div.signpost-content-header {
  height: 20px;
}

app-mercury-sync-status-signpost.itech-signpost-grid-detail div.signpost-content-header > .close {
  font-size: 1rem;
}

.signpost-edit-button {
  position: absolute;
  top: 0;
  z-index: 1000;
  max-width: 20px;
  min-width: 20px;
  max-height: 20px;
  min-height: 20px;
  padding: 4px;
  margin: 2px;
  display: flex;
  border: none;
  background: #fff;
  color: #0079b8;
  right: 24px;
}

app-mercury-sync-status-signpost.itech-signpost-grid-detail div.form-group {
  font-size: 11px;
  line-height: 0.5rem;
}

app-mercury-sync-status-signpost.itech-signpost-grid-detail div.form-group div.itech-text-field {
  margin-top: 0.5rem;
}

app-mercury-sync-status-signpost.itech-signpost-grid-detail div.form-group div.itech-text-field.itech-control-large {
  word-break: break-word;
  overflow: hidden;
}

app-product-transaction-detail-cell-renderer {
  overflow: auto;
  position: absolute;
  height: 445px;
  width: 100%;
  padding: 0 10px 10px 10px;
}

.product-expertise-list-sync-state-project {
  border: 1px solid;
  border-radius: 8px;
  padding: 1px 0 1px 3px;
  background: #f9f9f999;
  font-weight: normal;
}

.product-expertise-list-sync-state {
  color: blue;
  border: 1px solid blue;
  border-radius: 8px;
  padding: 1px 0 1px 3px;
  background: #eef9;
  font-weight: normal;
}

.product-expertise-list-sync-state-final-result {
  color: green;
  border: 1px solid green;
  border-radius: 8px;
  padding: 1px 2px;
  background: #e9ffe9;
}

.product-expertise-common-sync-status {
  position: absolute;
  top: 15px;
  font-size: 11px;
  font-weight: normal;
  display: flex;
}

.product-expertise-common-sync-status > app-mercury-sync-status-signpost > div.trigger-item > clr-signpost.itech-sync-status-lbl > a.itech-inline-link-btn.signpost-trigger {
  display: flex;
  align-items: center;
}

clr-stack-view.stack-view-expertise-indicators > .stack-view > clr-stack-block > dt.stack-block-label {
  min-width: 80%;
}

clr-stack-view.stack-view-expertise-indicators > .stack-view > clr-stack-block > dd.stack-block-content {
  width: 20%;
}

clr-stack-block.stack-view-expertise-indicators-inner-block > dt.stack-block-label {
  min-width: 100%;
}

clr-stack-block.stack-view-expertise-indicators-inner-block > dd.stack-block-content {
  width: 0;
}

.form-block.itech-block-medium.itech-expertise-area {
  padding-right: .5rem;
}

.edit-animal-list-flex {
  margin-top: 5px;
}

.edit-animal-list-flex .itech-many-animal {
  display: flex;
  flex-direction: column;
}

.edit-animal-list-flex .itech-many-animal a {
  margin: 0;
}

.app-ag-grid-no-editable-cell {
  background-color: #efeded45;
  cursor: not-allowed;
}

.app-ag-grid-no-editable-cell *:hover {
  cursor: not-allowed;
}

app-edit-animal-event-common-form .ag-set-filter-item,
app-query-details-results .ag-set-filter-item {
  display: flex;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

section.sidenav-content::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #949494;
}

table.itech-telemetry-tbl {
  border-collapse: collapse;
  width: 100%;
}

table.itech-telemetry-tbl td, table.itech-telemetry-tbl th {
  border: 1px solid #ddd;
  padding: 4px;
}

table.itech-telemetry-tbl tr:nth-child(even) {
  background-color: #f2f2f2;
}

table.itech-telemetry-tbl tr:hover {
  background-color: #ddd;
}

table.itech-telemetry-tbl > thead td {
  background-color: #50A0A0;
  color: white;
  text-align: center;
  font-weight: bold;
  min-width: 40px;
}

table.itech-telemetry-tbl > tbody td {
  vertical-align: top;
}

table.itech-telemetry-tbl > tbody td:nth-child(1) {
  width: 54%;
  max-width: 54%;
}

table.itech-telemetry-tbl > tbody td:nth-child(2) {
  width: 20%;
  max-width: 20%;
  text-align: center;
}

table.itech-telemetry-tbl > tbody td:nth-child(3) {
  width: 20%;
  max-width: 20%;
  text-align: center;
}

table.itech-telemetry-tbl > tbody td:nth-child(4) {
  width: 20%;
  max-width: 20%;
  text-align: center;
}

app-edit-animal-event-common-form .immutable-field-icon {
  color: gray;
  vertical-align: top;
  margin-top: 16px;
}

.form .form-group .app-link-button-auto-width > div > div.itech-text-field.itech-control-xlarge {
  width: initial;
}

.pulse {
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: pulse 2s 5;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #04fa23;
  }
  70% {
    -webkit-box-shadow: 0 0 0 50px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 #04fa23;
    box-shadow: 0 0 0 0 #04fa23;
  }
  70% {
    -moz-box-shadow: 0 0 0 50px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 50px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

app-edit-animal-common-form .cert-icon {
  color: green;
  height: 23px;
  width: 23px;
}

.it-pst-tab-col-tbl {
  border-left: 1px solid black;
  border-top: 1px solid black;
  font-family: 'Times New Roman', serif;
  font-size: 12px;
  line-height: 12px;
  color: black;
  background-color: white;
  user-select: none;
}

.it-pst-tab-col-tbl thead td {
  font-weight: bold;
  text-align: center;
  vertical-align: center;
  background-color: #eee;
  word-break: break-all;
}

.it-pst-tab-col-tbl thead td.it-vertical span {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  height: 60px;
}

.it-pst-tab-col-tbl thead tr:last-child td.it-indicator {
  width: 60px;
}

.it-pst-tab-col-tbl td {
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  padding: 3px;
  position: relative;
}

.it-pst-tab-col-tbl .btn {
  margin: -3px -3px;
}

.it-pst-tab-col-tbl .it-row-header {
  font-weight: bold;
  background-color: #eee;
}

.it-pst-tab-col-tbl .it-row-footer {
  font-weight: bold;
  background-color: #eee;
}

.it-pst-tab-col-tbl tbody td.it-indicator {
  text-align: right;
  width: 60px;
}

.it-pst-tab-col-tbl tbody td.it-indicator.it-static-text {
  text-align: left;
}

.it-pst-tab-col-tbl .it-read-only {
  background-color: #eee;
}

.it-pst-tab-col-tbl tbody .it-indicator-icon {
  position: absolute;
  left: -1px;
  top: -1px;
  width: 16px;
  height: 16px;
  user-select: none;
}

.it-pst-tab-col-tbl tbody .it-indicator-icon clr-icon {
  opacity: .7;
  scale: .7;
  cursor: default;
}

.it-pst-tab-col-tbl tbody .it-focus-border {
  display: none;
  position: absolute;
  left: -1px;
  right: -1px;
  top: -1px;
  bottom: -1px;
  border: 2px solid #1a73e8;
  pointer-events: none;
  user-select: none;
}

.it-pst-tab-col-tbl:focus {
  outline: none;
}

.it-pst-tab-col-tbl tbody td.it-indicator.it-focused .it-focus-border {
  display: block;
}

.it-pst-tab-col-tbl tbody td.it-indicator.it-focused .it-focus-border.it-cell-storing {
  display: block;
  border: 2px solid silver;
  background-color: rgba(200, 200, 200, .5);
  pointer-events: auto;
  user-select: none;
}

.it-pst-tab-col-tbl tbody td.it-indicator.it-focused .it-focus-border.it-cell-error {
  display: block;
  border: 2px solid red;
  background-color: rgba(200, 100, 100, .5);
}

.it-pst-tab-col-tbl tbody td.it-indicator.it-focused.it-editing .it-focus-border {
  box-shadow: 0 2px 6px 2px rgba(60, 64, 67, 0.15);
}

.it-pst-tab-col-tbl tbody td.it-indicator.it-focused.it-editing input,
.it-pst-tab-col-tbl tbody td.it-indicator.it-focused.it-editing .select {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: none;
  pointer-events: auto;
  user-select: auto;
  width: 100%;
  height: 100%;
}

.it-pst-tab-col-tbl tbody td.it-indicator.it-focused.it-editing .select select {
  max-height: 100%;
}

.it-pst-tab-col-tbl-wrapper {
  overflow-x: scroll;
  padding-bottom: 5px;
}

.it-title-cell-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 15;
  background-color: #eee;
  display: flex;
  align-items: center;
  border-left: 1px solid black;
  border-right: 2px solid black;
  padding: 2px;
}

.it-pst-tab-col-tbl .it-cell-error-text {
  display: block;
  position: absolute;
  left: -1px;
  top: calc(100% - 1px);
  width: 300px;
  height: auto;
  border: 2px solid red;
  background-color: rgb(250, 100, 100);
  color: white;
  pointer-events: none;
  user-select: none;
  z-index: 10;
  font-family: Roboto, serif;
  text-align: left;
  word-break: break-all;
  padding: 5px;
}

.it-pst-tab-col-tbl .it-cell-info-text {
  display: block;
  position: absolute;
  left: -1px;
  top: calc(100% - 1px);
  width: 600px;
  height: 600px;
  border: 2px solid #1a73e8;
  color: white;
  pointer-events: none;
  user-select: none;
  z-index: 10;
  font-family: Roboto, serif;
  text-align: left;
  word-break: break-all;
  padding: 5px;
}

.it-fed-header-tbl {
  border: none;
  font-family: 'Times New Roman', serif;
  font-size: 12px;
  line-height: 14px;
  color: black;
  background-color: white;
  user-select: none;
}

.it-fed-header-tbl .it-fed-header-cell {
  border: 1px solid black;
  padding: 5px;
  text-align: center;
  vertical-align: center;
}

.it-fed-header-tbl .it-fed-header-skip-row {
  height: 10px;
}

.it-fed-header-tbl .it-bold {
  font-weight: bold;
}

.it-fed-header-tbl .it-fed-header-cell.it-left {
  text-align: left;
}

.it-pst-tab-col-tbl tbody td .it-add-subtype {
  pointer-events: auto;
  position: absolute;
  right: 2px;
  top: 2px;
}

.it-pst-tab-col-tbl tbody td .it-add-subtype clr-dropdown {
  margin: 0;
}

.it-pst-tab-col-tbl tbody td .it-add-subtype .dropdown a.dropdown-toggle {
  padding: 0;
  margin: 0;
  color: #0094d2;
  background: #eee;
}

.itech-flex-link-with-remove {
  flex: 1000 1 auto
}

.itech-flex-link-with-remove > button {
  min-height: 30px;
}

.form .form-group.itech-flex-link-with-remove .itech-text-field {
  width: auto;
}

.form .form-group.itech-flex-link-with-remove .spinner.spinner-inline {
  margin: 0;
}

.checkbox-inline.invalid {
  color: #c92100;
}

.checkbox-inline.invalid input[type=checkbox]+label::before {
  border-color: #c92100;
}

.checkbox-inline.checkbox-middle > input {
  top: 50%;
  transform: translateY(-0.3rem);
}

.checkbox-inline.checkbox-middle input[type=checkbox]+label:before {
  top: 50%;
  transform: translateY(-0.3rem);
}

.checkbox-inline.checkbox-middle input[type=checkbox]+label:after {
  top: 50%;
  transform: translateY(-0.16rem) rotate(-45deg);
}

div.form-group.itech-group-whole-line > .checkbox-inline.checkbox-middle:not(:last-child) > label > div:last-child {
  border-bottom: 1px solid silver
}

.itech-header.header .notification-row > * {
  font-size: 12px;
  overflow: hidden;
}

.itech-header.header .notification-row.empty-notification-label {
  text-align: left;
  margin-left: 10px;
  color: silver;
}

.itech-header.header .notification-row > td {
  padding-top: 0;
  padding-bottom: 0;
  text-align: left;
}

.itech-header.header .notification-row > td > div.notification-text, a.notification-text {
  padding: 0;
  font-size: 12px;
  white-space: pre-wrap;
  height: auto;
  line-height: .5833334rem;
}

.itech-header.header .notification-row > td > app-link-button.notification-link {
  padding: 0;
}

.itech-header.header .notification-row > td > app-link-button > * {
  font-size: 12px;
}

.itech-header.header .notification-row > clr-icon.notification-result-icon {
  position: relative;
  top: 4px;
  margin-left: 5px;
}

app-grid.app-grid-header-wrap div.ag-header-cell-label > span {
  white-space: pre-wrap !important;
}

.sidenav .collapsible label.icon-before-text:before {
  content: '';
  float: left;
  height: 0.416667rem;
  width: 0.416667rem;
  transform: translateX(-0.333333rem) translateY(0.291667rem);
  background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2012%2012%22%3E%0A%20%20%20%20%3Cdefs%3E%0A%20%20%20%20%20%20%20%20%3Cstyle%3E.cls-1%7Bfill%3A%239a9a9a%3B%7D%3C%2Fstyle%3E%0A%20%20%20%20%3C%2Fdefs%3E%0A%20%20%20%20%3Ctitle%3ECaret%3C%2Ftitle%3E%0A%20%20%20%20%3Cpath%20class%3D%22cls-1%22%20d%3D%22M6%2C9L1.2%2C4.2a0.68%2C0.68%2C0%2C0%2C1%2C1-1L6%2C7.08%2C9.84%2C3.24a0.68%2C0.68%2C0%2C1%2C1%2C1%2C1Z%22%2F%3E%0A%3C%2Fsvg%3E%0A);
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
  margin: 0;
}

.sidenav .collapsible label.icon-before-text:after {
  display: none;
}

.trash-icon {
  color: red;
  background: none;
  border: none;
}

div.app-input-tag {
  position: absolute;
  left: 0;
  top: 0;
}

div.app-input-tag > clr-dropdown > button.app-input-tag-icon {
  max-width: 20px;
  min-width: 20px;
  min-height: 20px;
  max-height: 20px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 0 0 0 10px !important;
  font-size: 12px;
  line-height: 14px;
  height: 18px;
}

.dropdown-menu button.app-input-tag-icon.dropdown-item.expandable:before {
  margin-top: 0;
}

div.app-input-tag > clr-dropdown > button.app-input-tag-icon > clr-icon {
  position: absolute;
  top: 5px;
  left: 0;
}

.form-group.itech-double-height > label.tooltip-validation > app-input-text > textarea {
  padding-top: 0;
  margin-top: 0;
  height: 2rem;
  max-height: 2rem;
  min-height: 2rem;
  overflow-y: hidden;
}

.ag-theme-balham .ag-row {
  overflow-y: auto;
}

textarea.no-resize {
  resize: none;
}

ag-grid-angular.app-grid-editable > div.ag-root-wrapper {
  overflow: visible;
}

clr-tree-node.it-clr-tree-expanded > div.clr-treenode-children {
  overflow-y: visible !important;
}

.it-invalid-badge {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20version%3D%221.1%22%20viewBox%3D%225%205%2026%2026%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Cdefs%3E%3Cstyle%3E.clr-i-outline%7Bfill%3A%23a32100%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Ctitle%3Eexclamation-circle-line%3C%2Ftitle%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20class%3D%22clr-i-outline%20clr-i-outline-path-1%22%20d%3D%22M18%2C6A12%2C12%2C0%2C1%2C0%2C30%2C18%2C12%2C12%2C0%2C0%2C0%2C18%2C6Zm0%2C22A10%2C10%2C0%2C1%2C1%2C28%2C18%2C10%2C10%2C0%2C0%2C1%2C18%2C28Z%22%3E%3C%2Fpath%3E%3Cpath%20class%3D%22clr-i-outline%20clr-i-outline-path-2%22%20d%3D%22M18%2C20.07a1.3%2C1.3%2C0%2C0%2C1-1.3-1.3v-6a1.3%2C1.3%2C0%2C1%2C1%2C2.6%2C0v6A1.3%2C1.3%2C0%2C0%2C1%2C18%2C20.07Z%22%3E%3C%2Fpath%3E%3Ccircle%20class%3D%22clr-i-outline%20clr-i-outline-path-3%22%20cx%3D%2217.95%22%20cy%3D%2223.02%22%20r%3D%221.5%22%3E%3C%2Fcircle%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  height: 0.666667rem;
  width: 0.666667rem;
  vertical-align: middle;
}

.ag-body-viewport.ag-layout-normal {
  overflow-x: scroll;
}

.it-sticky-top {
  position: sticky !important;
  top: 0;
  z-index: 3;
}

.it-pst-tab-col-tbl-wrapper {
  overflow-x: auto;
  overflow-y: auto;
  padding-bottom: 10px;
}

.pst-table-header-row {
  font-size: 14px;
  font-weight: bold;
  text-align: center !important;
}

app-link-button.itech-link-button-no-margin > div > div.itech-text-field.itech-control-xlarge {
  margin: 0;
}

.it-btn-sync-web-caption {
  max-height: 18px;
  display: flex;
  align-items: center;
}
